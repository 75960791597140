@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Cormorant-Bold';
  src: local('Cormorant-Bold'),
    url(@thrivelot/assets/Cormorant-Bold.otf) format('opentype');
}
@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins-Bold'),
    url(@thrivelot/assets/Poppins-Bold.otf) format('opentype');
}
@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins-Regular'),
    url(@thrivelot/assets/Poppins-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'Rokkitt-SemiBold';
  src: local('Rokkitt-SemiBold'),
    url(@thrivelot/assets/Rokkitt-SemiBold.ttf) format('truetype');
}

section {
  @apply text-sm leading-6 my-0 mx-auto px-5 py-12 max-w-[600px];
  color: rgba(51, 51, 51, 1);
}

section h2 {
  @apply font-header inline-block text-3xl text-mulch-dark leading-none mx-0 mt-0 mb-1 align-top;
}

section p {
  @apply font-normal text-brown-dark mx-0 my-4;
}

section a {
  @apply font-normal text-green-main no-underline;
}

section ul {
  @apply font-normal text-brown-dark mx-0 my-4 pl-8;
}

section li {
  @apply mb-2;
}

section .tip {
  @apply font-normal bg-evergreen-light text-green-dark rounded-2xl inline-block text-[11px] leading-3 mr-2 py-1 px-3 align-top;
}

section .tip-wrapper {
  @apply font-normal text-xs text-brown-dark leading-5 my-10;
}

section .tip-wrapper svg {
  @apply inline-block h-3 mt-1 mr-1 align-top w-3;
}

section .tip-wrapper svg path {
  @apply fill-green-main;
}

.storybook-button {
  @apply font-header border-2 rounded cursor-pointer inline-block leading-none uppercase hover:shadow-hover hover:opacity-80 transition-all duration-500;
}
.storybook-button--primary {
  @apply bg-green-main text-white-light border-transparent shadow-outer;
}
.storybook-button--secondary {
  @apply bg-transparent text-tan-dark border-tan-dark shadow-inner;
}
.storybook-button--small {
  @apply text-xs py-1 px-2;
}
.storybook-button--medium {
  @apply text-sm py-2 px-4;
}
.storybook-button--large {
  @apply text-base py-3 px-6;
}

.input {
  @apply font-normal text-sm caret-tan-dark text-brown-dark placeholder:text-tan-main placeholder:font-normal border border-tan-light hover:ring-1 hover:ring-tan-light focus:border-tan-dark focus:ring-1 focus:ring-tan-dark bg-white-dark w-full px-2 py-2 outline-none rounded shadow-inset transition-all duration-300;
}

.tip-arrow,
.tip-arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.tip-arrow {
  visibility: hidden;
}

.tip-arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

.input-label {
  @apply block font-accent text-mulch-dark text-sm ml-2 mb-0.5;
}

.wrapper {
  @apply border-tan-light border-solid border-b flex items-center justify-between py-4 px-5;
}

h1 {
  @apply font-title font-black inline-block text-xl text-mulch-dark leading-none my-1 mr-0 ml-3 align-top;
}

h2 {
  @apply font-header inline-block text-xl text-mulch-dark leading-none my-1 mr-0 ml-2 align-top;
}

p {
  @apply font-normal inline-block text-sm text-brown-dark leading-none;
}

button + button {
  @apply ml-2;
}
