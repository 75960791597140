.phone-input-rounded {
  display: flex;
  border: 0px none transparent;
  transition: all ease-out 0.5s;
  font-family: Rokkitt-Bold;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 5px;
  min-height: 33px;
  max-height: 33px;
  -webkit-appearance: none;
}
@media (max-width: 767px) {
  .phone-input-rounded {
    font-size: 14px;
  }
}
@media (min-width: 768px) {
  .phone-input-rounded {
    font-size: 17px;
  }
}
.phone-dark {
  background-color: #3c362f;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25);
  caret-color: #b98956;
  color: #f5e8d3;
}
.phone-dark::selection {
  background-color: #b98956;
  color: #f5e8d3;
}
.phone-dark::placeholder {
  color: #f5e8d380;
  opacity: 1;
}
.phone-dark:-ms-input-placeholder {
  color: #f5e8d380;
}
.phone-dark::-moz-placeholder {
  color: #f5e8d380;
}
.phone-dark:focus {
  outline: none !important;
  box-shadow: -1px -2px 4px rgba(0, 0, 0, 0.15), 1px 2px 4px rgba(0, 0, 0, 0.25);
  color: #f5e8d3;
  background-color: #322d27;
}
.phone-dark:focus::placeholder {
  color: #f5e8d380;
  opacity: 1;
}
.phone-dark:focus:-ms-input-placeholder {
  color: #f5e8d380;
}
.phone-dark:focus::-moz-placeholder {
  color: #f5e8d380;
}
.phone-light {
  background-color: #faf5ef;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25);
  caret-color: #00a172;
  color: #7c543d;
}
.phone-light::selection {
  background-color: #00a172;
  color: #faf5ef;
}
.phone-light::placeholder {
  color: #7c543d9a;
  opacity: 1;
}
.phone-light:-ms-input-placeholder {
  color: #7c543d9a;
}
.phone-light::-moz-placeholder {
  color: #7c543d9a;
}
.phone-light:focus {
  outline: none !important;
  box-shadow: -1px -2px 4px rgba(245, 232, 211, 0.2), 1px 2px 4px rgba(133, 115, 109, 0.25);
  color: #7c543d;
  background-color: #f5e8d3;
}
.phone-light:focus::placeholder {
  color: #7c543d9a;
  opacity: 1;
}
.phone-light:focus:-ms-input-placeholder {
  color: #7c543d9a;
}
.phone-light:focus::-moz-placeholder {
  color: #7c543d9a;
}
