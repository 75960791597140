.rbc-month-view {
  @apply border-none flex flex-col flex-grow flex-shrink-0 h-full relative select-none w-full;
  flex-basis: 0px;
}

.rbc-month-row {
  @apply border-solid border-t border-tan-light;
}

.rbc-month-row + .rbc-month-row {
  @apply border-solid border-t border-tan-light;
}

.rbc-off-range-bg {
  @apply bg-transparent;
  background: transparent;
}

.rbc-day-bg {
  @apply flex-grow flex-shrink-0;
  flex-basis: 0px;
}

.rbc-day-bg + .rbc-day-bg {
  @apply border-solid border-l border-tan-light;
}

.rbc-selected-day-bg {
  @apply flex-grow flex-shrink-0 bg-green-main cursor-pointer;
  flex-basis: 0px;
}

.rbc-day-bg + .rbc-selected-day-bg {
  @apply border-solid border-l border-tan-light;
}

.rbc-selected-day-bg + .rbc-day-bg {
  @apply border-solid border-l border-tan-light;
}

.rbc-today {
  @apply bg-tan-light/40;
}

.rbc-header + .rbc-header {
  @apply border-solid border-l border-l-tan-light;
}

.rbc-header {
  @apply border-solid border-b border-b-tan-light flex-grow flex-shrink-0 min-h-0 py-0 px-1 text-center align-middle truncate;
  flex-basis: 0px;
  font-size: 90%;
}

.rbc-time-header-gutter {
  @apply w-[62px] min-w-[62px] max-w-[62px];
}

.rbc-toolbar {
  @apply relative flex flex-wrap items-center justify-center h-14 px-3;
}

.rbc-toolbar .rbc-toolbar-label {
  @apply font-header text-xl;
}

.rbc-calendar {
  @apply rounded-sm border-solid border border-tan-light box-border flex flex-col items-stretch h-full;
}

.rbc-month-header {
  @apply hidden;
}

.rbc-label {
  @apply py-0 px-1 font-header text-xs;
}

.rbc-time-view {
  @apply border-solid border-t border-b-0 border-l-0 border-r-0 border-tan-light flex flex-col flex-grow flex-shrink min-h-0 w-full;
}

.rbc-time-content > * + * > * {
  @apply border-solid border-l border-l-tan-light;
}

.rbc-day-slot .rbc-time-slot {
  @apply border-t border-solid border-t-tan-light;
}

.rbc-timeslot-group {
  @apply border-solid border-b border-b-tan-light flex;
  min-height: 40px;
  flex-flow: column nowrap;
}

.rbc-row-content {
  z-index: auto;
}

.rbc-current-time-indicator {
  @apply h-px pointer-events-none absolute inset-x-0 bg-green-main;
}

.rbc-time-header-content {
  @apply border-solid border-l border-l-tan-light flex flex-col flex-grow flex-shrink min-w-0;
}

.rbc-time-header.rbc-overflowing {
  @apply border-r-0;
}

.rbc-time-content {
  @apply border-solid border-t-2 border-t-tan-light flex items-start flex-grow flex-shrink-0 overflow-y-auto relative w-full;
  flex-basis: 0px;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  @apply rounded box-border cursor-pointer m-0 px-1 py-px shadow-none text-left font-accent text-white-light w-full bg-blue-light z-10;
}

.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  @apply bg-blue-light;
}

.rbc-agenda-view table.rbc-agenda-table {
  @apply border-collapse border-solid border w-full border-tan-light border-l-0 border-r-0;
  border-spacing: 0;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  @apply border-l border-solid border-l-tan-light;
}

.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  @apply font-header text-tan-dark text-xs;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  @apply border-solid border-t border-t-tan-light;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  @apply py-1 px-2 align-top;
}

.rbc-agenda-event-cell {
  @apply w-full font-normal text-mulch-dark;
}

.rbc-show-more {
  @apply text-sm font-accent ml-1 text-blue-main bg-transparent;
}

.rbc-agenda-empty {
  @apply font-normal italic text-brown-dark mx-3 my-3;
}
